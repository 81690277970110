<template>
    <div class="box">
        <Header type="expert" :navList="navList" :typeList="typeList" :navCurrentId="navCurrentId" :logo="logoUrl"></Header>

        <div class="content">
            <a-spin :spinning="loading">
                <template v-if="!loading">
                    <!--    内容上边部分   -->
                    <div class="top-main flex x-left">
                        <div class="left-box">
                            <!-- 一层 -->
                            <div class="flex home-left-floor1">
                                <a-carousel class="carousel" arrows  autoplay :autoplay-speed="4000" style="cursor: pointer">
                                    <!--                            autoplay-->
                                    <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
                                        <a-icon type="left-circle" />
                                    </div>
                                    <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                                        <a-icon type="right-circle" />
                                    </div>
                                    <img v-for="(item,index) in swiperData" :key="index" :src="item.img | urlFilter(360)" :alt="item.title" @touchstart="handleBanerToPage(item)" @click="handleBanerToPage(item)">
                                </a-carousel>
                                <div v-if="newsList.length" class="hlf-news ml20 flex-item t-l">
                                    <router-link :to="{path:'/infomation-detail',query:{id:newsList[0].id,type:newsList[0].type}}">
                                        <div :title="newsList[0].news_title" class="strong fs20 color333 row-2">
                                            {{newsList[0].news_title}}
                                        </div>
                                        <!-- <div class="fs14 color999 mt5 row-1">
                                            {{newsList[0].keywords}}
                                        </div> -->
                                    </router-link>
                                    <router-link v-for="(item, index) in newsList.slice(1,4)" :key="index" class="hlf-news-item mt5 color333 fs16 row-2" :to="{path:'/infomation-detail',query:{id:item.id,type:item.type}}">
                                        <span :title="item.news_title">{{item.news_title}}</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="right-box flex-item">
                            <common-title path="/" title="本周人气专家" :isMore="false" />
                            <div class="top-list-block">
                                <div class="top-list-item flex x-left y-center" v-for="(item,index) in topList" :key="index" @click="expertJumpDetail(item.user_id)">
                                    <div class="top-info flex flex-item x-left">
                                        <div class="count flex x-center">
                                            <img src="~@/assets/images/expert-ranking-one.png" v-if="index == 0">
                                            <img src="~@/assets/images/expert-ranking-two.png" v-if="index == 1">
                                            <img src="~@/assets/images/expert-ranking-three.png" v-if="index == 2">
                                        </div>
                                        <div class="top-left-box ml10">
                                            <div class="name-subject flex x-left y-bottom">
                                                <span class="name fs16 strong">{{item.real_name}}</span>
                                                <span class="subject fs12 ml10">{{item.department}}</span>
                                            </div>
                                            <div class="company">{{item.org_cnname}}</div>
                                        </div>
                                    </div>
                                    <div class="fans-num fs16">
                                        粉丝：{{item.fans_num}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--   主体列表部分   -->
                    <!--    院士风采   -->
                    <div class="charming-style mt40">
                        <common-title path="/expert/ys-mien" title="院士风采">
                            <div class="sub-head ml10" slot="subheading">*（排名不分先后）</div>
                        </common-title>
                        <div class="list-box">
                            <expert-list :dataList="academicianList"></expert-list>
                        </div>
                    </div>
                    <!--   学科主委   -->
                    <div class="subject-chair mt40">
                        <common-title path="/expert/committee" title="学科主委">
                            <div class="sub-head ml10" slot="subheading">*（排名不分先后）</div>
                        </common-title>
                        <div class="list-box">
                            <expert-list :dataList="chairList"></expert-list>
                        </div>
                    </div>
                </template>
            </a-spin>

        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import commonTitle from '@/components/common-title';
import Header from '@/components/layout/layout-header';
import expertList from '@/components/expert-list'
import Footer from '@/components/layout/layout-footer'
import { expertNavList, exportLogo } from '@/config/const'
import { handleBanerToPage, expertJumpDetail } from '@/utils/jumpPageMethods'
import { jsonFormat } from '@/utils/jsonFormat'
export default {
    name: "ExpertIndex",
    components: {
        commonTitle,
        Header,
        expertList,
        Footer
    },
    data() {
        return {
            logoUrl: exportLogo,
            navList: expertNavList,//顶部菜单
            navCurrentId: 2,//当前页面菜单对应下标
            topList: [],//人气专家排行榜
            academicianList: [],// 院士风采列表
            chairList: [],//学科主委列表
            swiperData: [],
            newsList: [],
            loading: true,//加载框
            typeList: [
                { id: 6, name: '全部', path: '/expert/user-search',selected:true },
                { id: 7, name: '院士', path: '/expert/ys-mien' },
                { id: 8, name: '主委', path: '/expert/committee' },
                { id: 9, name: '关注', path: '/expert/follow' },
            ]
        }
    },
    created() {
        this.pageInit();
    },
    methods: {
        handleBanerToPage,
        expertJumpDetail,
        getExpertToplist() {
            return this.request.post('PopularityExperts', { limit: 3, page: 1 })
        },
        getBannerList() {
            return this.request.post('GetBanner', { banner_type: 7 })
        },
        getYsList() {
            return this.request.post('YsList', { limit: 8, page: 1 })
        },
        getNewsList() {
            return this.request.post('BigGetNews', {
                is_expert: 1,
                is_ykx_index: 1,
                limit: 5,
                page: 1,
                type: 6,
            })
        },
        getCommittee() {
            //学科组委
            return this.request.post('AllCurrentChairman', { committee_id: 50, limit: 8, page: 1 })
        },
        pageInit() {
            let requestArr = [
                this.getBannerList(),
                this.getExpertToplist(),
                this.getYsList(),
                this.getCommittee(),
                this.getNewsList()
            ]
            Promise.all(requestArr).then(response => {
                /**** banner START *****/
                let { data } = response[0];
                this.swiperData = data;
                /**** banner END *****/

                /**** 专家排行 START *****/
                this.topList = response[1].data.list;
                /**** 专家排行 END *****/

                /**** 院士风采 START *****/
                let ysData = response[2].data.list;
                let formatTemplate = {
                    name: 'real_name',
                    subject: 'department',
                    company: 'org_cnname',
                    avatar: 'avatar',
                    introduce: 'resume',
                    user_id: 'user_id'
                }
                this.academicianList = jsonFormat(ysData, formatTemplate);
                /**** 院士风采 END *****/


                /**** 学科组委 START *****/
                let formatTemplate1 = {
                    name: 'real_name',
                    subject: 'department',
                    company: 'org_cnname',
                    avatar: 'avatar',
                    introduce: 'resume',
                    user_id: 'UserID'
                }
                this.chairList = jsonFormat(response[3].data.list, formatTemplate1);
                /**** 学科组委 END *****/

                /**** 资讯 START *****/
                this.newsList = response[4].data.list
                /**** 资讯 END *****/

                this.loading = false;
            })
        }
    }
}
</script>

<style lang="less" scoped>
.sub-head {
    color: #999999;
}
.box {
    background: url("~@/assets/images/expert-nav-top-bg.png") repeat-x;
    background-size: contain;
    .content {
        min-height: 400px;
        width: 1240px;
        margin: 0 auto;
        padding: 0 20px 28px;
        .top-main {
            .left-box {
                width: calc(66.66% - 30px);
                margin-right: 30px;
                .carousel {
                    width: 400px;
                    height: 225px;
                }
            }
            .right-box {
                .top-list-block {
                    .top-list-item {
                        margin-bottom: 17px;
                        cursor: pointer;
                    }
                    .top-info {
                        text-align: left;
                        .count {
                            width: 14px;
                            padding-top: 4px;
                            img {
                                height: 12px;
                                width: auto;
                            }
                        }
                        .top-left-box {
                            .name-subject {
                                .name {
                                    color: @title;
                                }
                                .subject {
                                    color: @desc;
                                }
                            }
                            .company {
                                color: #666666;
                            }
                        }
                    }
                    .fans-num {
                        color: @title;
                    }
                }
            }
        }
        /*院士风采 && 学科组委*/
        .charming-style,
        .subject-chair {
            .list-box {
                margin-top: 22px;
            }
        }
    }
}

.ant-carousel /deep/ .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    border-radius: 50%;
    opacity: 0.3;
}
.ant-carousel /deep/ .custom-slick-arrow:before {
    display: none;
}
.ant-carousel /deep/ .custom-slick-arrow:hover {
    opacity: 1;
}

.ant-carousel /deep/ .slick-slide h3 {
    color: #fff;
}
@media screen and (max-width:768px){
    .box{
        .content{
            width: 100%;
            padding: 0;
            .top-main{
                display: block;
                .left-box{
                    width: 100%;
                    .carousel{width: 100%;height:auto;}
                    .home-left-floor1{
                        display: block;
                        .hlf-news{
                            margin-left: 0;
                            padding: 0 10px;
                        }
                    }
                }
                .right-box{
                    margin-top: 20px;
                    padding: 0 10px;
                }
            }
            .charming-style,.subject-chair{
                padding: 0 10px;
            }
        }
    }
    
}
</style>