<template>
    <!-- type = ma为综合专题相关页面 -->
    <div class="common-title-ma flex y-center mb10" v-if="type == 'ma'">
        <div class="common-text flex y-center pt10 pb10 ">
            <h2 class="common-title-ma-text fs20 x-left">{{ title }}</h2>
        </div>
        <slot name="subheading"></slot>
        <router-link v-if="isMore" class="color999 fs12 more" :to="{ path: path }">更多 <i
                class="med med-right"></i></router-link>
    </div>
    <div class="common-title flex y-center" v-else>
        <h2 class="common-title-text fs20 pt10 pb10 x-left">{{ title }}</h2>
        <slot name="subheading"></slot>
        <router-link v-if="isMore" class="color999 fs12 more" :to="{ path: path }">更多 <i
                class="med med-right"></i></router-link>
    </div>
</template>

<script>
export default {
    name: 'commonTitle',
    props: {
        type: {
            type: String,
            default: '', //type=ma 综合专题相关页面
        },
        title: {
            type: String,
            default: '',
        },
        isMore: {
            type: Boolean,
            default: true,
        },
        path: {
            type: String,
            default: '',
        }
    }
}
</script>

<style scoped lang="less">
.common-title {
    width: 100%;
    border-top: 1px solid #f1f1f1;

    &-text {
        color: @theme;
        border-top: 2px solid @theme;
        line-height: 1;
        display: inline-block;
        margin-top: -2px;
        font-weight: bold;
    }
}
.common-title-ma{
    &-text {
        color: @theme;
        border-left: 2px solid @theme;
        line-height: 1;
        display: inline-block;
        padding-left: 10px;
        font-weight: bold;
        margin-bottom: 0;
    }
}

.more {
    margin-left: auto
}

@media screen and (max-width:768px){
    .common-title{
        &-text{
            font-size:16px;
        }
    }
}

</style>
