<template>
    <div class="component_box">
        <div class="item-box flex x-left y-center">
            <div :class="['item', 'mb20' , (index + 1) % 4 == 0 ? '' : 'mr20' ]" v-for="(item,index) in dataList" :key="index" @click="expertJumpDetail(item.user_id,item.id,item.jump_type,$route.params.id)">
                <!-- 头像 -->
                <div class="head-portrait">
                    <img :src="item.avatar | urlFilter(250,'avatar') || require('@/assets/images/person-default.png')" @error="setDefaultImage" :alt="item.name">
                </div>
                <div class="card">
                    <!--  正面   -->
                    <div class="front">
                        <div class="name-tag flex x-center y-bottom">
                            <span class="name strong">{{item.name}}</span>
                            <span :class="[type == 2 ? 'colorred':'']" class="subject ml5 row-1">{{item.subject}}</span>
                        </div>
                        <div class="company row-2">
                            {{item.company}}
                        </div>
                    </div>
                    <!--  反面   -->
                    <div class="contrary">
                        <div class="detail t-l row-4" v-if="item.introduce">
                            {{item.introduce}}
                        </div>
                        <div :class="[item.introduce ? 'more t-r flex y-center x-right' : '']">查看详情 <i class="med med-right"></i> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /*
    * 格式模板
    * {
        name : 'real_name', 名称
        subject : 'department', 学科
        company : 'org_cnname', 单位名称
        avatar : 'avatar', 头像
        introduce : 'resume' 介绍
        user_id : user_id 用户id
      }
    *
    * */
    import { expertJumpDetail } from '@/utils/jumpPageMethods'
    export default {
        name: "expert-list",
        props : {
          dataList : {
              type : Array,
              default : []
          },
          type:{
            type:String,
            default:'1'
          }
        },
        data(){
            return {

            }
        },
        methods : {
            expertJumpDetail,
            setDefaultImage(e){
                e.target.src = require('@/assets/images/person-default.png')
            }
        }
    }
</script>

<style lang="less" scoped>
    .component_box{
        width: 100%;
        .item-box{
            flex-wrap: wrap;
            .item{
                height: 213px;
                width: 284px;
                position: relative;
                cursor: pointer;
                .head-portrait{
                    width: 100px;
                    height: 100px;
                    border-radius: 50px;
                    overflow: hidden;
                    background-color: lightskyblue;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 10;
                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                    }
                }
                .card{
                    width: 100%;
                    height: 163px;
                    background: #F9F9F9;
                    border-radius: 10px;
                    padding: 70px 23px 0;
                    box-sizing: border-box;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: all 1s;
                    overflow: hidden;
                    .front{
                        .name-tag{
                            .name{
                                font-size: 18px;
                                color: @title;
                                flex-shrink: 0;
                            }
                            .subject{
                                max-width: 180px;
                                color: @desc;
                                font-size: 14px;
                                flex-shrink: 0;
                            }
                            .colorred{
                                color: red;
                            }
                        }
                        .company{
                            color: #666666;
                            font-size: 16px;
                            text-align: center;
                        }
                    }
                }
                .contrary{
                    opacity: 0;
                    font-size: 12px;
                    color: #ffffff;
                    line-height: 16px;
                    .more{
                        font-size: 10px;
                    }
                }
                &:hover .front{
                    display: none;
                }
                &:hover .card{
                    background-color: #D76B45 !important;
                }
                &:hover .contrary{
                    opacity: 1;
                    color: #ffffff;
                }
            }
        }
    }
    @media screen and (max-width:768px){
        .item-box{
            justify-content: flex-start;
            .item{
                margin-right: 15px!important;
                width: calc((100% - 30px) / 3)!important;
                &:nth-child(3n+3){
                    margin-right: 0!important;
                }
            }
            
        }
    }
    @media screen and (max-width:550px){
        .item-box{
            justify-content: space-between;
            .item{
                width: calc((100% - 10px) / 2)!important;
                margin-right: 0!important;
            }
        }
    }
    @media screen and(min-width:450px) and (max-width:768px){
        .name-tag,.company{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
    }
    @media screen and (max-width:450px){
        .item-box{
            .item{
                width: 100%!important;
                margin-right: 0!important;
                .name-tag,.company{
                    word-wrap: break-word;
                }
            }
        }
    }
</style>